body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

h1 {
    font-size: 4vh !important;
}

h2 {
    font-size: 3vh !important;
}

h3 {
    font-size: 2vh !important;
}
